import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Signup from '../views/Signup';
import Login from '../views/Login';
import Contact from '../views/Contact';
import Me from '../views/Me';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/me',
    component: Me
  },
  {
    path: '/creators',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "creators" */ '../views/Creator.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "creators" */ '../views/Creator/Home.vue')
      },
      {
        path: 'new-channel',
        component: () => import(/* webpackChunkName: "creators" */ '../views/Creator/NewChannel.vue')
      },
      {
        path: 'channels/:requestedId',
        props: true,
        component: () => import(/* webpackChunkName: "creators" */ '../views/Creator/ChannelHome.vue'),
        children: [
          {
            path: 'edit',
            component: () => import(/* webpackChunkName: "creators" */ '../views/Creator/EditChannel.vue'),
            props: true
          },
          {
            path: 'new-post',
            component: () => import(/* webpackChunkName: "creators" */ '../views/Creator/NewPost.vue'),
            props: true
          },
          {
            path: 'posts',
            component: () => import(/* webpackChunkName: "creators" */ '../views/Creator/ChannelIndex.vue'),
            props: true
          },
          {
            path: 'posts/:postId',
            component: () => import(/* webpackChunkName: "creators" */ '../views/Creator/EditPost.vue'),
            props: true
          },
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
    /*
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
     */
  }
})

export default router
