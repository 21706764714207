<template>
  <v-img class="uploadable-image" :aspect-ratio="aspectRatio">
    <center-middle>
      <v-btn fab
             :loading="isSelecting"
             dark
             x-small
             depressed
             @click.prevent="uploadIntent"
             @click="onButtonClick">
        <v-icon dark>mdi-camera-plus</v-icon>
      </v-btn>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
        @cancel="onFileCancel"
      >
    </center-middle>
  </v-img>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  export default {
    data: () => ({
      selectedFile: null,
      isSelecting: false
    }),
    props: {
      endpoint: {
        type: String,
        required: true
      },
      role: {
        type: String,
        required: true
      },
      aspectRatio: {
        type: Number,
        default: 1
      }
    },
    components: {},
    watch: {},
    mounted() {

    },
    computed: {
      ...mapGetters({

      })
    },
    methods: {
      ...mapActions({

      }),
      uploadIntent() {

      },
      onButtonClick() {
        this.isSelecting = true
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.uploader.click()
      },
      onFileCancel() {
        this.isSelecting = false;
      },
      onFileChanged(e) {
        if (!e.target.files || !e.target.files[0]) {
          this.isSelecting = false;
          return;
        }

        this.selectedFile = e.target.files[0];

        let formData = new FormData();

        let filename;
        if (this.selectedFile.type == 'image/jpeg') {
          filename = "image.jpg";
        } else {
          filename = "image.png";
        }

        formData.append('image', this.selectedFile, {
          name: filename,
          type: this.selectedFile.type
        });

        formData.set('role', this.role);

        return this.$http.post(this.endpoint, formData).then(res => {
          console.log('POST', this.endpoint, res.data);
          this.$emit('uploaded', res.data);
        }).catch(res => {
          console.error(res);
        }).finally(() => {
          this.isSelecting = false;
          this.selectedFile = null;
        });
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
