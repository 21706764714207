
const state = {
  members: [
    {
      name: 'Ashley K.',
      roles: ['Customer Service', 'Creator Rep'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/ashley-k.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/ashley-k.jpg'
    },
    {
      name: 'Emily C.',
      roles: ['Customer Service', 'Creator Rep'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/emily-c.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/emily-c.jpg'
    },
    {
      name: 'Joshua A.',
      roles: ['Developer', 'Infrastructure', 'DevOps'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/joshua-a.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/joshua-a.jpg'
    },
    {
      name: 'Kevin L.',
      roles: ['Developer', 'Mobile'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/kevin-l.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/kevin-l.jpg'
    },
    {
      name: 'Mandi Q.',
      roles: ['Developer', 'Front-end'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/mandi-q.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/mandi-q.jpg'
    },
    {
      name: 'Morgan P.',
      roles: ['Customer Service', 'Consumer Rep'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/morgan-p.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/morgan-p.jpg'
    },
    {
      name: 'Nicole T.',
      roles: ['Customer Service', 'Creator Rep'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/nicole-t.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/nicole-t.jpg'
    },
    {
      name: 'Olivia S.',
      roles: ['Marketing', 'Advertising'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/olivia-s.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/olivia-s.jpg'
    },
    {
      name: 'Sophia K.',
      roles: ['Accounting', 'Admin'],
      img: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/sophia-k.jpg',
      thumb: 'https://xb001.sfo2.digitaloceanspaces.com/assets/team/thumbs/sophia-k.jpg'
    }
  ],
}

const getters = {
  members: state => {
    return state.members;
  }
}

const actions = {

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
