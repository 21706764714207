<template>
  <div class="signup">

    <v-container>
      <v-row>
        <v-col offset-sm="3" sm="6">

          <h2 class="headline mb-3 text-uppercase">JOIN XUBLISHER</h2>

          <p class="mb-12">
            You are a trailblazing bad-ass.  Please provide a your first and last name along with the email and a secure password you'd like to use to log into
            your Xublisher account.
          </p>

          <v-form
            autocomplete="off"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              autofocus
              outlined
              v-model="name"
              :counter="40"
              :rules="nameRules"
              label="Full Name"
              hint="This is never displayed, ever."
              :persistent-hint="true"
              required
              class="mb-4"
            ></v-text-field>

            <v-text-field
              outlined
              prepend-inner-icon="mdi-at"
              v-model="username"
              :counter="30"
              :rules="usernameRules"
              label="Username"
              hint="The only thing ever publicly visible, period."
              :persistent-hint="true"
              required
              autocapitalize="off"
              class="mb-4"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="email"
              name="email"
              :rules="emailRules"
              label="E-mail"
              :error="errors.has('email')"
              :error-messages="errors.first('email')"
              @keydown="clearApiErrorField('email')"
              required
              hint="Never displayed, ever. Just in case you forget your password."
              :persistent-hint="true"
              type="email"
              autocapitalize="off"
              class="mb-4"
            ></v-text-field>

            <v-text-field
              autocomplete="new-password"
              v-model="password"
              :append-icon="revealPassword ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              :type="revealPassword ? 'text' : 'password'"
              name="password"
              label="Password"
              :rules="passwordRules"
              hint="Between 8-20 characters with 1 special character (sorry)"
              :persistent-hint="true"
              @click:append="revealPassword = !revealPassword"
              required
              class="mb-6"
            ></v-text-field>

            <div class="mb-9 mt-0">
              <recaptcha container="signup"></recaptcha>
            </div>

            <alert v-if="errors.has('recaptcha')">
              {{errors.first('recaptcha')}}
            </alert>

            <v-btn
              :disabled="!valid || !completed"
              :loading="loading"
              block
              large
              color="primary"
              class="mt-4"
              @click="validate"
            >
              JOIN XUBLISHER
            </v-btn>

            <div class="mt-4 text-center">
              By contacting us, you agree to our
              <a href="/policy/terms" target="_blank" rel="noopener noreferrer" class="">Terms of Use</a>,
              <a href="/policy/privacy"  target="_blank" rel="noopener noreferrer" class="">Privacy Policy</a> and
              <a href="/policy/cookies"  target="_blank" rel="noopener noreferrer" class="">Cookie Policy</a>.
            </div>

          </v-form>


          <div class="text-center my-8">
            Already have an account? Same. <router-link to="login">Sign In</router-link>.
          </div>


        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import Recaptcha from "../components/Recaptcha";
  import {mapGetters, mapActions} from 'vuex';

  export default {
    components: {Recaptcha},
    data: () => ({
      loading: false,
      valid: false,
      //name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2 && v.length <= 40) || 'Name must be between 3 and 30 characters',
      ],
      //username: '',
      usernameRules: [
        v => !!v || 'Username is required',
        v => (v && v.length <= 30 && v.length >= 2 && /^[a-zA-Z0-9]+$/.test(v)) || 'Username must be between 3 and 30 characters',
      ],
      //email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      //password: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8 && v.length <= 20 && /[%^&()!@#$]+/.test(v)) || 'Password must be 8 characters and have at least one of % ^ & ( ) ! @ # $',
      ],
      revealPassword: false,
    }),
    watch: {
      id(newVal) {
        if (newVal)
          this.$router.replace('creators');
      },
      allRecaptchas() {
        this.clearApiErrorField('recaptcha');
      }
    },
    computed: {
      ...mapGetters({
        id: 'auth/id',
        errors: 'auth/errors',
        recaptchas: 'recaptcha/responses',
        allRecaptchas: 'recaptcha/allResponses'
      }),
      completed() {
        return this.email && this.name && this.password && this.recaptchas.for('signup');
      },
      name: {
        get() {
          return this.$store.state.auth.name;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {name: val});
        }
      },
      username: {
        get() {
          return this.$store.state.auth.username;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {username: val});
        }
      },
      email: {
        get() {
          return this.$store.state.auth.email;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {email: val});
        }
      },
      password: {
        get() {
          return this.$store.state.auth.password;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {password: val});
        }
      }
    },
    methods: {
      ...mapActions({
        signUp: 'auth/signUp',
        clearApiErrorField: 'auth/clearApiErrorField'
      }),
      async validate () {
        this.$refs.form.validate();

        await this.signUp();
        if (this.id)
          this.$router.replace('/creators');

      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
