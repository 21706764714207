
const state = {
  errors: [],
  drawer: false
}

const getters = {
  drawer: state => {
    return state.drawer;
  },
  errors: state => {
    return state.errors;
  }
}

const actions = {
  update({ commit, state, dispatch }, data) {
    commit('UPDATE', data);
  }
}

const mutations = {
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });

    //ls.persist(localStorageKey, data, lsKeyBase);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
