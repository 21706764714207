
const state = {
  component: null,
  title: null,
  buttons: [],
  locked: false,
  payload: null,
  onCreated: (p) => {},
  onUpdated: (p) => {}
}

const getters = {
  component: state => state.component,
  payload: state => {
    if (!state.payload)
      return {};

    return state.payload;
  },
  title: state => state.title,
  buttons: state => state.buttons,
}

const actions = {
  onUpdated({ state, dispatch }, payload) {
    if (state.onUpdated)
      state.onUpdated(payload);
  },
  onCreated({ state, dispatch }, payload) {
    if (state.onUpdated)
      state.onUpdated(payload);
  },
  update({ commit, state, dispatch }, data) {
    commit('UPDATE', data);
  },
  closeModal({ commit, state, dispatch }) {
    commit('UPDATE', {
      component: null,
      title: null,
      buttons: [],
      locked: false,
      payload: null,
      onCreated: (p) => {},
      onUpdated: (p) => {}
    });
  },
  setModal({ commit, state, dispatch }, payload) {
    console.log('store.modal.setModal', payload);
    commit('UPDATE', payload);
  },
  setComponent({ commit, state, dispatch }, component) {
    //console.log('store.app.setModalComponent', component);
    //console.trace("setModalComponent");
    commit('SET_COMPONENT', component);
  },
  setTitle({ commit, state, dispatch }, title) {
    commit('SET_TITLE', title);
  },
  setButtons({ commit, state, dispatch }, buttons) {
    commit('SET_BUTTONS', buttons);
  },
  mergeButton({ commit, state, dispatch }, button) {
    commit('MERGE_BUTTON', button);
  },
}

const mutations = {
  SET_COMPONENT: (state, component) => {
    state.component = component;
  },
  SET_TITLE: (state, title) => {
    state.modalTitle = title;
  },
  SET_BUTTONS: (state, buttons) => {
    state.modalButtons = buttons;
  },
  MERGE_BUTTON:  (state, button) => {
    if (!Array.isArray(state.buttons) || !button.name)
      return;

    let idx = state.buttons.findIndex(e => e.name === button.name);
    if (idx < 0)
      return;

    state.buttons[idx] = Object.assign(state.buttons[idx], button);
  },
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
