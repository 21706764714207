<template>
  <div class="attached-image-editor">
    <v-sheet tile color="grey">
      <adjustable-image
        v-if="attachment"
        :attachable="attachment"
        :editable="true"
        :endpoint="`${endpoint}/${attachment.id}`"
        :aspect-ratio="aspectRatio"
        @adjusted="onImageAdjusted"
      ></adjustable-image>
      <uploadable-image
        v-else
        :aspect-ratio="aspectRatio"
        :role="role"
        :endpoint="endpoint"
        @uploaded="onImageUploaded"
      ></uploadable-image>
    </v-sheet>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import AdjustableImage from "./AdjustableImage";
  import UploadableImage from "./UploadableImage";
  export default {
    data: () => ({
      attachment: null
    }),
    props: {
      //attachment: {
      //  type: Object
      //},
      attachable: {
        type: Object
      },
      aspectRatio: {
        type: Number,
        default: 1
      },
      endpoint: {
        type: String
      },
      role: {
        type: String
      }
    },
    components: {
      AdjustableImage,
      UploadableImage
    },
    watch: {},
    mounted() {
      if (this.attachable)
        this.attachment = this.attachable;
    },
    computed: {
      ...mapGetters({

      })
    },
    methods: {
      ...mapActions({

      }),
      onImageAdjusted(attachment) {
        this.attachment = attachment;
        this.$emit('updated', this.attachment);
      },
      onImageUploaded(attachment) {
        console.log('onImageUploaded', attachment);
        this.attachment = attachment;
        this.$emit('created', attachment);
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
