<template>
  <div class="home">
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <section class="mb-8 pb-8">
      <v-responsive style="max-width:1390px;" class="mx-auto">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <creator-bubbles style="min-height: 260px;"></creator-bubbles>
            </v-col>
            <v-col cols="12" sm="6">
              <h1 class="mb-1">CONTENT BEGINS</h1>
              <h2 class="mb-5">IN THE <v-icon>mdi-cards-heart</v-icon> OF THE CREATOR</h2>

              <!--<div>
                the access to which is a privilege, not a right.  The creator, no one else, will decide where,
                when and with whom it is shared.
              </div>-->

              <p class="font-weight-thin mb-8">
                We're Xublisher.  We protect your creations and help you share them
                with those who truly respect you.  If we don't protect creators,
                they'll stop creating. Nobody wants that.
              </p>

              <v-btn rounded dark color="teal" :to="{ path: 'signup', query: { intent: 'create' }}">
                CREATE WITH US
              </v-btn>
            </v-col>

          </v-row>
        </v-container>
      </v-responsive>
    </section>

    <section class="py-6">
      <v-responsive style="max-width:1390px;" class="mx-auto">
        <v-container fluid>
          <v-row>
            <v-col
              class="text-center"
              cols="12"
              sm="6"
              md="3"
              v-for="feature in features"
              :key="feature.title"
            >
              <v-icon class="mb-2" x-large :color="feature.color">{{feature.icon}}</v-icon>

              <h3 class="mb-4">{{feature.title}}</h3>

              <p class="font-weight-thin">{{feature.text}}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src

import CreatorBubbles from "../components/CreatorBubbles";

export default {
  name: 'Home',
  data() {
    return {
      features: [
        {title:'Overshare Protection', color:'purple', icon: 'mdi-shield-account',
          text: "Not ready to share? Block by location, Facebook/LinkedIn location, employer name, first and last name, payment card zip code, proxy service and more."},
        {title:'Anti-theft', color:'teal darken-2', icon: 'mdi-shield-lock',
          text: "Set up to five layers of identity verification allowing us to uniquely tag the files consumed by your fans preventing them from sharing illegally."},
        {title:'Protect Earnings', color:'cyan darken-2', icon: 'mdi-shield-star',
          text: 'Xublisher has created a new era of anti-piracy for user generated content.  There is simply no better way to protect your income than to block sharing.'},
        {title:'Verify Fans', color:'deep-orange darken-2', icon: 'mdi-shield-alert',
          text: "You choose precisely who can see your content using our unbeatable technology.  Our hackers have infiltrated every proxy service for your protection."},
      ]
    }
  },
  components: {
    CreatorBubbles

  }
}
</script>
