<template>
  <div class="creator-bubbles" ref="creatorBubbles">
    <div class="creator-bubble"
         v-for="image in images"
         :key="image.title"
         :style="{'background-image':`url(${image.url})`, width:`${image.size}px`, height: `${image.size}px`, top: `${image.top}%`, left: `${image.left}%`, opacity: image.opacity}"
    ></div>
  </div>
</template>

<script>

  export default {
    components: {},
    data: () => ({
      el: null,
      offset: 0,
      scrollListener: null,
      images: [
        {title: '1', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-01.jpg", size: 80, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '2', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-02.jpg", size: 50, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '3', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-03.jpg", size: 120, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '4', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-04.jpg", size: 70, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '5', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-05.jpg", size: 90, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '7', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-07.jpg", size: 60, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '10', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-10.jpg", size: 40, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '11', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-11.jpg", size: 30, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '12', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-12.jpg", size: 60, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '13', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-13.jpg", size: 70, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        {title: '14', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-14.jpg", size: 90, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        //{title: '15', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-15.jpg", size: 110, coeff: 1, iTop: 0, top: 0, left: 0, opacity: 0},
        // too sexy
        //{title: '8', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-08.jpg", size: 100, coeff: 1, iTop: 0, top: 0, left: 0},
        //{title: '9', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-09.jpg", size: 40, coeff: 1, iTop: 0, top: 0, left: 0},
        //{title: '6', url: "https://xb001.sfo2.digitaloceanspaces.com/assets/bubbles/xublisher-bubbles-06.jpg", size: 80, coeff: 1, iTop: 0, top: 0, left: 0},

      ]
    }),
    mounted() {

      this.el = this.$refs.creatorBubbles;
      let containerWidth = this.$refs.creatorBubbles.offsetWidth;
      let containerHeight = this.$refs.creatorBubbles.offsetHeight;
      console.log();
      this.images = this.images.map(e => {
        let maxX = containerWidth - e.size;
        let maxY = containerHeight - e.size;
        e.top = Math.floor(Math.random() * maxY) / containerHeight * 100;
        e.coeff = Math.random();
        e.iTop = e.top;
        e.left = Math.floor(Math.random() * maxX) / containerWidth * 100;
        setTimeout(() => {
          e.opacity = 1;
        }, Math.random() * 4000);
        return e;
      })

      console.log(this.images);

      this.scrollListener = this.throttle(this.observeScroll, 50);

      window.addEventListener('scroll', this.scrollListener);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollListener);
    },
    methods: {
      observeScroll(e) {
        let scrolled = window.pageYOffset;
        this.offset = Math.floor(scrolled * .2);

        this.images = this.images.map(e => {
          e.top = e.iTop - (this.offset * e.coeff);
          return e;
        })

        //console.log(this.offset);
      },
      throttle(func, wait, options) {
        var context, args, result;
        var timeout = null;
        var previous = 0;
        if (!options) options = {};
        var later = function() {
          previous = options.leading === false ? 0 : Date.now();
          timeout = null;
          result = func.apply(context, args);
          if (!timeout) context = args = null;
        };
        return function() {
          var now = Date.now();
          if (!previous && options.leading === false) previous = now;
          var remaining = wait - (now - previous);
          context = this;
          args = arguments;
          if (remaining <= 0 || remaining > wait) {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
          } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
          }
          return result;
        };
      }
    }
  }
</script>

<style lang="scss">
  .creator-bubbles {
    position: relative;
    width: 100%;
    height: 100%;

    .creator-bubble {
      transition: top 300ms, opacity 2s;
      opacity: 0;
      position: absolute;
      background-position: center center;
      background-size: cover;
      top: 0%;
      left: 0%;
      border-radius: 50%;
    }
  }
</style>
