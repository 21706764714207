import throttle from '../../utils/throttle';

export const errorableState = () => {
  return {
    errors: []
  };
}

export const errorableGetters = () => {
  return {
    errors: state => {
      return {
        ...state.errors,
        has(field) {
          return state.errors.findIndex(e => e.field === field) > -1;
        },
        first(field) {
          let error = state.errors.find(e => e.field === field);
          if (!error) return null;
          return error.error;
        },
        all() {
          if (state.errors.length > 0)
            return state.errors;

          return [];
        },
        any() {
          if (state.errors.length > 0)
            return true;

          return false;
        }
      };
    }
  };
}

export const errorableActions = (rules) => {
  return {
    /**
     * setApiErrors
     * Set api response errors (specific to axios and laravel...feel free to remove)
     *
     * @param commit
     * @param state
     * @param error
     * @returns {null}
     */
    setApiErrors( {commit, state}, error ) {
      if (
        !error
        || !error.response
        || !error.response.data
        || !error.response.data.errors
      ) {
        return null;
      }

      let errors = error.response.data.errors;

      Object.keys(errors).map(k => {
        commit("CLEAR_ERROR", k);
        commit("ADD_ERROR", {field: k, error: errors[k][0]});
      });
    },
    clearApiErrorField( {commit, state}, field ) {
      commit("CLEAR_ERROR", field);
    },
    validate: throttle(({commit, state}, fields) => {
      let result = true;

      if (!fields || !Array.isArray(fields)) {
        fields = rules.map(r => r.field);
      }

      fields = fields.reduce((a, f) => {
        a[f] = true;
        return a;
      }, {});

      let keyedRules = rules.reduce((a, r) => {
        if (!Array.isArray(a[r.field]))
          a[r.field] = [];

        a[r.field].push(r);
        return a;
      }, {});

      Object.keys(fields).map(f => {
        commit("CLEAR_ERROR", f);

        if (!keyedRules[f])
          return;

        keyedRules[f].map(r => {
          if (!r.validator(state[f])) {
            commit("ADD_ERROR", {field: f, error: r.error});
            result = false;
          }
        })
      })

      return result;
    }, 400)
  };
}

export const errorableMutations = () => {
  return {
    CLEAR_ERRORS: (state) => {
      state.errors = [];
    },
    CLEAR_ERROR: (state, field) => {
      let errorIdx = state.errors.findIndex(e => e.field === field);
      if (errorIdx > -1) {
        state.errors.splice(errorIdx, 1);
      }
    },
    ADD_ERROR: (state, {field, error}) => {
      let errorIdx = state.errors.findIndex(e => e.field === field);
      if (errorIdx > -1) {
        state.errors.splice(errorIdx, 1);
      }

      state.errors.push({field, error});
    },
  };
}
