<template>
  <v-app>
    <app-drawer-main></app-drawer-main>
    <app-bar-main></app-bar-main>

    <div class="mt-12 spacer">
      <div v-if="loaded">
        <v-img src="https://xb001.sfo2.digitaloceanspaces.com/assets/xublisher-my-home-girl-01.jpg"
               height="80px"
               position="55% 30%"
               class="mb-8"
               v-if="false"
        ></v-img>

        <v-container>
          <div v-if="appErrors.length > 0" class="mb-4">
            <alert v-for="error in appErrors" :key="error" type="error" class="mb-4">{{error}}</alert>
          </div>
        </v-container>

        <router-view></router-view>
      </div>
      <div v-else class="fill-height">
        <loading></loading>
      </div>
    </div>

    <app-modal></app-modal>

    <footer-main></footer-main>
  </v-app>
</template>

<script>

  import {mapGetters, mapActions} from 'vuex';

  import AppBarMain from "./components/AppBarMain";
  import AppDrawerMain from "./components/AppDrawerMain";
  import FooterMain from "./components/FooterMain";
  import AppModal from "./components/AppModal";
  import {EventBus} from "./utils/events";

  export default {
    name: 'App',

    components: {
      AppModal,
      FooterMain,
      AppBarMain,
      AppDrawerMain,
    },

    data: () => ({
      loaded: false
    }),
    async mounted() {
      window.recaptchaCallback = () => {
        setTimeout(() => {
          this.recaptchaSetReady();
        }, 500)
      };

      // Intro, get xsrf cookie.
      await this.$http.get('hello');
      setInterval(async () => {
        await this.$http.get('hello');
      }, 60000 * 10)

      let result = await this.loadMe({bypassModal: true});

      if (this.id) {
        await this.wsConnect();
      }

      this.$eventBus.$on('authentication-required', () => {
        this.setModal({
          component: 'LoginForm',
          title: 'Session Expired',
          buttons: []
        });
      });

      this.$eventBus.$on('authenticated', () => {
        // close any modal
        this.closeModal();
      })

      this.loaded = true;
    },
    computed: {
      ...mapGetters({
        drawer: 'app/drawer',
        id: 'auth/id',
        email: 'auth/email',
        email_verified_at: 'auth/email_verified_at',
        appErrors: 'app/errors',
        awaiting_auth: 'auth/awaiting_auth'
      })
    },
    methods: {
      ...mapActions({
        appUpdate: 'app/update',
        recaptchaSetReady: 'recaptcha/setReady',
        loadMe: 'auth/loadMe',
        wsConnect: 'ws/connect',
        logout: 'auth/logout',
        setModal: 'modal/update',
        closeModal: 'modal/closeModal'
      }),
    }
  };
</script>

<style type="text/css" lang="scss">
  body {
    background-color: #0b2e13;

    h1, h2 {
      font-family: Anton, sans-serif;
      letter-spacing: 1px;
      font-weight: normal;
    }

    h1 {
      font-size: 50px;
      line-height: 45px;
    }

    h2 {
      font-size: 30px;
      line-height: 30px;
    }
  }
</style>
