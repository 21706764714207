<template>
  <div class="app-bar-main">
    <v-app-bar
      color="#33333d"
      flat
      fixed
      dense
    >
      <!-- color="deep-purple accent-4" dark -->
      <v-app-bar-nav-icon @click.prevent="appUpdate({drawer: !drawer})"></v-app-bar-nav-icon>

      <!-- flag and text -->
      <v-img src="https://xb001.sfo2.digitaloceanspaces.com/assets/xublisher-logo-horizontal.png"
             class="d-none d-lg-block"
             max-height="40"
             max-width="132"
             contain>
      </v-img>

      <!-- flag only -->
      <v-img src="https://xb001.sfo2.digitaloceanspaces.com/assets/xublisher-flag-small.png"
             class="d-lg-none"
             max-height="40"
             max-width="35"
        contain>
      </v-img>

      <!--
      <v-toolbar-title>Page title</v-toolbar-title>
      -->


      <v-spacer></v-spacer>

      <v-btn rounded dark text small class="mr-2" to="login" v-if="!username">
        Log In
      </v-btn>
      <v-btn rounded dark text small class="mr-2" to="/me" v-else>
        @{{username}}
      </v-btn>

      <v-btn rounded dark depressed outlined small :to="createPath">
        Create
      </v-btn>

      <v-menu
        v-if="false"
        left
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="n in 5"
            :key="n"
            @click="() => {}"
          >
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    components: {

    },
    data: () => ({

    }),
    computed: {
      ...mapGetters({
        drawer: 'app/drawer',
        username: 'auth/username'
      }),
      createPath() {
        if (!this.username)
          return { path: 'signup', query: { intent: 'create' }};

        return '/creators';
      }
    },
    methods: {
      ...mapActions({
        appUpdate: 'app/update'
      })
    }
  }
</script>

