<template>
  <v-footer
    padless
    light
  >
    <v-card
      flat
      tile
      class="white--text py-12"
      style="background-color: rgb(46, 46, 53)"
      width="100%"
    >
      <v-card-text class="text-center">
        <v-btn
          v-for="social in socials"
          :key="social.title"
          class="mx-4 white--text"
          :href="social.url"
          icon
        >
          <v-icon size="24px">{{ social.icon }}</v-icon>
        </v-btn>
      </v-card-text>


        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <div class="title mb-5">Work@Xublisher</div>
              <div class="font-weight-light mb-7">
                Would you like to join the Xublisher movement!? We're changing
                creating forever and we're always in search of people like us!
              </div>

              <v-carousel
                cycle
                dark
                :next-icon="false"
                :prev-icon="false"
                height="200px"
                hide-delimiters
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="member in teamMembers"
                  :key="member.name"
                >
                  <v-card>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4">TEAM XUBLISHER</div>
                        <v-list-item-title class="headline mb-1">{{member.name}}</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip
                            class="ma-1"
                            small
                            v-for="role in member.roles"
                            :key="role"
                          >
                            {{role}}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                        tile
                        size="80"
                        color="grey"
                      >
                        <v-img :src="member.thumb"></v-img>
                      </v-list-item-avatar>
                    </v-list-item>
                  </v-card>
                </v-carousel-item>
              </v-carousel>

            </v-col>
            <v-col cols="12" sm="4">
              <div class="title mb-5">Become a Creator</div>
              <div class="font-weight-light mb-7">
                Would you like to join the Xublisher movement!? We're changing
                creating forever and we're always in search of people like us!
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="title mb-5">Get in Touch</div>
              <div class="font-weight-light mb-7">
                Would you like to join the Xublisher movement!? We're changing
                creating forever and we're always in search of people like us!
              </div>
            </v-col>
          </v-row>
        </v-container>


      <v-divider></v-divider>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} — <strong>Xublisher</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    data: () => ({
      socials: [
        {icon: 'mdi-facebook', title: 'Facebook', url: "https://facebook.com/xublisher"},
        {icon: 'mdi-twitter', title: 'Twitter', url: "https://twitter.com/xublisher"},
        {icon: 'mdi-linkedin', title: 'LinkedIn', url: "https://linkedin.com/company/xublisher"},
        {icon: 'mdi-instagram', title: 'Instagram', url: "https://instagram.com/xublisherofficial"},
      ],
    }),
    computed: {
      ...mapGetters({
        teamMembers: 'team/members'
      })
    }
  }
</script>
