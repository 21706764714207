import Vue from 'vue';

import {EventBus} from "../utils/events";

const state = {
  socket: null,
  messages: [],
  last: null
}

const getters = {
  messages: state => {
    return state.messages;
  },
  last: state => {
    return state.last;
  }
}

const actions = {
  async connect({commit, state, dispatch}) {
    function heartbeat() {
      clearTimeout(this.pingTimeout);

      this.pingTimeout = setTimeout(() => {
        commit('UPDATE', {client:null});
        this.terminate();
      }, 30000 + 1000);
    }

    let auth = await Vue.http.get('ws/sign');
    //console.log(auth.status);
    if (auth.status !== 200)
      return;

    let url = "wss://local.xublisher.com:8070";
    console.log("process.env.NODE_ENV", process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production')
      url = "wss://xublisher.com:8080";

    let qs = new URLSearchParams(auth.data).toString();
    const socket = new WebSocket(`${url}?${qs}`);

    commit('UPDATE', {socket});

    socket.addEventListener('message',  event => {
      try {
        const payload = JSON.parse(event.data);
        console.log('ws', payload);
        EventBus.$emit('ws', payload);
      } catch (e) {
        console.error('Failed to parse ws payload.', event.data);
      }
    });

    socket.addEventListener('error',  error => {
      console.error(`WebSocket error: ${error}`)
    })

    socket.addEventListener('open', () => {
      console.log('onopen');
    });

    //socket.onping('ping', heartbeat);
    socket.addEventListener('close', function clear() {
      console.log('onclose');
      clearTimeout(this.pingTimeout);
    });
  },
  update({ commit, state, dispatch }, data) {
    commit('UPDATE', data);
  },
}

const mutations = {
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
