<template>
  <div class="login">

    <v-container>
      <v-row>
        <v-col offset-sm="3" sm="6">

          <h2 class="headline mb-3 text-uppercase">SIGN IN</h2>

          <p class="mb-12">
            If you haven't yet create a Xublisher account please <router-link to="/signup" rel="noopener noreferrer" class="">Sign Up</router-link> here
            to get started.
          </p>

          <login-form @succeeded="$router.replace('/creators')"></login-form>

          <div class="mt-4 text-center">
            By signing in, you agree to Xublisher's
            <router-link to="/policy/terms" target="_blank" rel="noopener noreferrer" class="">Terms of Use</router-link>,
            <router-link to="/policy/privacy"  target="_blank" rel="noopener noreferrer" class="">Privacy Policy</router-link> and
            <router-link to="/policy/cookies"  target="_blank" rel="noopener noreferrer" class="">Cookie Policy</router-link>.
          </div>

          <div class="text-center my-8">
            Forgot your password? <a href="/password/reset" target="_blank">Reset Password</a>.
          </div>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import LoginForm from "../components/LoginForm";

  export default {
    components: {LoginForm},
    data: () => ({

    }),
    computed: {
      ...mapGetters({

      })
    },
    methods: {
      ...mapActions({

      })
    },
  }
</script>
