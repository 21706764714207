import {errorableState, errorableMutations, errorableGetters, errorableActions} from "./mixins/errorable";
import Vue from 'vue';

const MAX_UPLOADING = 2;

const randQuotes = [
  "Why waste a sentence saying nothing?",
  "It's quite fun to do the impossible.",
  "If plan A fails, remember there are 25 more letters.",
  "Be specific enough to be believable and universal enough to be relevant.",
  "A journey of a thousand miles must begin with a single step.",
  "It's never too late to be who you might have been.",
  "The desire to reach for the stars is ambitious. The desire to reach hearts is wise.",
  "If opportunity doesn't knock, build a door.",
  "Be so good they can't ignore you.",
  "Content is king.",
  "If you cannot do great things, do small things in a great way.",
  "Business opportunities are like buses, there's always another one coming.",
  "Stay hungry. Stay foolish."
];

const state = {
  ...errorableState(),
  loading: true,
  id: null,
  is_draft: true,
  title: null,
  description: null,
  channel_id: null,
  published_at: null,
  attachments: [],
  tags: [],
  uploads: [],
  uploading: false,
  uploaded: false
}

const getters = {
  ...errorableGetters(),
  loading: state => state.loading,
  id: state => state.id,
  is_draft: state => state.is_draft,
  attachments: state => state.attachments,
  title: state => state.title,
  description: state => state.description,
  channel_id: state => state.channel_id,
  published_at: state => state.published_at,
  tags: state => state.tags,
  uploads: state => {
    return state.uploads;
  }
}

const actions = {
  ...errorableActions(),
  init({commit, state, dispatch}) {
    commit('UPDATE', {
      id: null,
      is_draft: true,
      title: null,
      description: randQuotes[Math.floor(Math.random() * randQuotes.length)],
      attachments: [],
      uploading: [],
      uploads: [],
      loading: false,
      tags: []
    });
  },
  async delete( {commit, state}) {
    return Vue.http.delete(`managed_posts/${state.id}`);
  },
  async load({commit, state, dispatch}, id) {
    dispatch('init');
    commit('UPDATE', {loading: true});

    await Vue.http.get(`managed_posts/${id}`).then(res => {
      commit('UPDATE', res.data);
    });

    commit('UPDATE', {loading: false});
  },
  loadAttachments({commit, state, dispatch}) {
    return Vue.http.get(`attachments`, {
      params: {
        attachable_type: 'Post',
        attachable_id: state.id
      }
    }).then(res => {
      commit('UPDATE', {attachments: res.data});
    });
  },
  update({ commit, state, dispatch }, data) {
    commit('UPDATE', data);
  },
  async uploadFiles({commit, state, dispatch}, files) {
    commit('UPDATE', {uploaded: false});

    if (!state.id) {
      console.log('store post initDraft')
      await dispatch('initDraft');
    }

    commit('QUEUE_UPLOADS', files);
    dispatch('ensureUploading');
  },
  onUploadAbort({commit, state, dispatch}, key) {
    dispatch('cancelUpload', key);
  },
  cancelUpload({commit, state}, key) {
    let idx = state.uploads.findIndex(e => {
      return e.key === key;
    });

    if (idx < 0 || state.uploads[idx].aborted)
      return;

    commit('UPDATE_UPLOAD', {
      key: key,
      data: {
        uploading: false,
        aborted: true
      }
    });

    state.uploads[idx].xhr.abort();
  },
  async ensureUploading({commit, state, dispatch}) {

    let uploadables = state.uploads.filter(e => {
      return !(e.uploading || e.uploaded || e.aborted || e.error);
    });

    if (uploadables.length === 0) {
      // nothing left to do
      commit('UPDATE', {uploaded: true});
      return;
    }

    let batch = uploadables.slice(0, MAX_UPLOADING);

    await Promise.all(batch.map(async upload => {
      let result = await Vue.http.post('upload_urls', {
        filesize: upload.file.size,
        filename: upload.file.name,
        mime_type: upload.file.type,
        attachable_type: "Post",
        attachable_id: state.id
      });

      upload.draft_upload = result.data;

      commit('UPDATE_UPLOAD', {
        key: upload.key,
        data: {
          uploading: true,
          draft_upload: upload.draft_upload
        }
      });

      await startXhrForUpload(upload, commit);

      await Vue.http.post(`upload_urls/${upload.draft_upload.id}/completion`);

      commit('UPDATE_UPLOAD', {
        key: upload.key,
        data: {
          uploading: false,
          uploaded: true,
          draft_upload: upload.draft_upload
        }
      });

      dispatch('loadAttachments');
    }));

    dispatch('ensureUploading');
  },
  async initDraft({ commit, state, dispatch }) {
    if (!state.channel_id) {
      return false;
    }

    return Vue.http.post('managed_posts', {
      channel_id: state.channel_id,
      title: state.title,
      description: state.description
    }).then(res => {
      return commit('UPDATE', {
        id: res.data.id
      });
    });
  },
  async save({ commit, state, dispatch }) {
    commit('UPDATE', {loading: true});

    await Vue.http.put(`managed_posts/${state.id}`, {
      title: state.title,
      description: state.description,
      is_draft: state.is_draft
    }).then(res => {
      return commit('UPDATE', res.data);
    });

    commit('UPDATE', {loading: false});
  },
  updateAttachment({ commit }, attachment) {
    commit('UPDATE_ATTACHMENT', attachment);
  }
}

const mutations = {
  ...errorableMutations(),
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  },
  QUEUE_UPLOADS: (state, files) => {
    state.uploading = true;

    files.map(file => {
      state.uploads.push({
        key: '_' + Math.random().toString(36).substr(2, 9),
        file: file,
        draft_upload: null,
        progress: 0,
        uploading: false,
        uploaded: false,
        aborted: false,
        error: null,
        xhr: null
      });
    });
  },
  UPDATE_UPLOAD: (state, {key, data}) => {
    let idx = state.uploads.findIndex(e => {
      return e.key === key;
    });

    if (idx === -1)
      return null;

    //console.log('UPDATE_UPLOAD', key, state.uploads[idx], data);

    Object.assign(state.uploads[idx], data);
  },
  UPDATE_ATTACHMENT: (state, attachment) => {
    let idx = state.attachments.findIndex(e => {
      return e.id === attachment.id;
    });

    if (idx === -1)
      return null;

    Object.assign(state.attachments[idx], attachment);
  }
}

async function startXhrForUpload(upload, commit, dispatch) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", (event) => {
      commit('UPDATE_UPLOAD', {key: upload.key, data: {progress: Math.ceil((event.loaded / event.total) * 100)}});
    }, false);

    xhr.addEventListener("load", () => {
      commit('UPDATE_UPLOAD', {key: upload.key, data: {uploading: false, uploaded: true}});
      resolve();
    }, false);

    xhr.addEventListener("error", () => {
      dispatch('onUploadAbort', upload.key);
      reject();
    }, false);

    xhr.addEventListener("abort", () => {
      commit('UPDATE_UPLOAD', {key: upload.key, data: {aborted: true} });
      reject();
    }, false);

    xhr.open('PUT', upload.draft_upload.signed_url);

    xhr.setRequestHeader('Content-Type', upload.file.type);

    //xhr.setRequestHeader('x-amz-acl', 'public-read'); // public-read
    xhr.send(upload.file);

    commit('UPDATE_UPLOAD', {key: upload.key, data: {xhr} });
  });
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
