import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import {EventBus} from "./utils/events";

import Loading from "./components/Loading"
Vue.component('Loading', Loading);

import Alert from "./components/Alert"
Vue.component('Alert', Alert);

import CenterMiddle from "./components/CenterMiddle"
Vue.component('CenterMiddle', CenterMiddle);

Vue.eventBus = EventBus;
Vue.prototype.$eventBus = EventBus;

Vue.config.productionTip = false

import Axios from 'axios';

Axios.defaults.baseURL = '/api';

Axios.interceptors.request.use(
  config => {
    //console.log(config);
    //config.headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');
    return config;
  },
  error => Promise.reject(error)
);

Axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.config && error.config.params && error.config.params.bypassModal)
    return resolve(Axios.request(error.config));

  if ([401, 403].indexOf(error.response.status) > -1) {
    return new Promise((resolve, reject) => {
      // login modal
      Vue.eventBus.$emit('authentication-required', error.response.status);
      Vue.eventBus.$on('authenticated', () => {
        resolve(Axios.request(error.config));
      });
    });
  } else {
    Vue.eventBus.$emit('error', error.response.data);
    return Promise.reject(error);
  }
});


Vue.prototype.$http = Axios;
Vue.http = Axios;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
