<template>
  <v-container>
    <h3>TODO: Edit my profile...</h3>

    <p>@{{username}} {{email}}, userid: {{id}}</p>

    <p>
      TODO: Oauth flow: facebook, twitter, instagram, linkedin
    </p>
  </v-container>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  export default {
    data: () => ({

    }),
    props: {
      requestedId: {
        type: Number,
        default: null
      }
    },
    components: {},
    watch: {},
    mounted() {

    },
    computed: {
      ...mapGetters({
        id: 'auth/id',
        username: 'auth/username',
        email: 'auth/email'
      })
    },
    methods: {
      ...mapActions({

      })
    }
  }
</script>

<style lang="scss" scoped>

</style>
