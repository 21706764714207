<template>
  <div class="recaptcha">
    <div :id="container" class="recaptcha-container"></div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    data: () => ({

    }),
    props: {
      container: {
        type: String,
        default: 'recaptcha'
      }
    },
    mounted() {
      if (this.recaptchaReady)
        this.recaptchaRender(this.container);
    },
    watch: {
      recaptchaReady(newVal, oldVal) {
        if (newVal === true) {
          //console.log('recaptchaReady, rendering...', newVal)
          this.recaptchaRender(this.container);
        }
      }
    },
    computed: {
      ...mapGetters({
        recaptchaReady: 'recaptcha/ready',
        recaptchaRendered: 'recaptcha/rendered'
      })
    },
    methods: {
      ...mapActions({
        recaptchaRender: 'recaptcha/render'
      })
    }
  }
</script>
<style lang="scss">

</style>
