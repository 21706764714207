<template>
  <v-dialog v-model="modal" persistent scrollable max-width="600px">
    <v-card>
      <v-card-title v-if="modalTitle">
        <span class="headline">{{modalTitle}}</span><!-- modal: {{modal}} {{modalComponent}} -->
      </v-card-title>
      <v-card-text style="height: 340px;">
        <v-container>
          <transition mode="out-in" name="fade">
            <component v-if="modalComponent"
                       :is="modalComponent"
                       @created="onCreated"
                       @updated="onUpdated"
                       v-bind="modalPayload"></component>
          </transition>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="modalButtons && modalButtons.length > 0">
        <v-spacer></v-spacer>
        <v-btn v-for="button in modalButtons" :key="button.name" @click.prevent="button.action">{{button.title}}</v-btn>
        <!--<v-btn color="blue darken-1" text @click="modal = false">Save</v-btn>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import AttachedImageEditor from "./AttachedImageEditor";
  import LoginForm from '../components/LoginForm';

  export default {
    data: () => ({

    }),
    props: {
      requestedId: {
        type: Number,
        default: null
      }
    },
    components: {
      AttachedImageEditor,
      LoginForm
    },
    watch: {

    },
    mounted() {

    },
    computed: {
      ...mapGetters({
        modalComponent: 'modal/component',
        modalPayload: 'modal/payload',
        modalTitle: 'modal/title',
        modalButtons: 'modal/buttons'
      }),
      modal: {
        get() {
          return this.$store.state.modal.component !== null;
        },
        set(val) {
          if (!val)
            this.$store.dispatch('modal/closeModal');
        }
      }
    },
    methods: {
      ...mapActions({
        onCreated: 'modal/onCreated',
        onUpdated: 'modal/onUpdated'
      })
    }
  }
</script>

<style lang="scss" scoped>

</style>
