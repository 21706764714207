<template>
  <v-alert
    border="right"
    colored-border
    :type="type"
    elevation="2"
  >
    <slot></slot>
  </v-alert>
</template>

<script>
  export default {
    name: 'Alert',
    props: {
      type: {
        type: String,
        default: 'info', // success, info, warning or error
        validator: function (value) {
          return ['success', 'info', 'warning', 'error'].indexOf(value) !== -1
        }
      }
    },
    data: () => ({

    }),
  }
</script>
