<template>
  <div class="adjustable-image" :style="{opacity}">
    <v-img :position="cssPosition" :aspect-ratio="aspectRatio" :src="attachable.url" @load="imageLoaded">
      <v-row align="end" class="white--text pl-2 fill-height" ref="dragger" :class="{draggable: editing}">
        <v-col v-if="editable">
          <div>
            <v-btn color="secondary" @click.prevent="onEditButtonClick" fab x-small>
              <v-icon v-if="!editing">mdi-cursor-move</v-icon>
              <v-icon v-else>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  export default {
    data: () => ({
      opacity: 0.01,
      editing: false,
      posX: 50,
      posY: 50,
      naturalWidth: 0,
      naturalHeight: 0,
      imageAspectRatio: 1,
      dragging: false,
      dragger: null,
      draggerWidth: 0,
      draggerHeight: 0,
      downPos: [0,0],
      vImgEl: null,
      deltaX: 0,
      deltaY: 0,
      // listeners
      downListener: () => {},
      upListener: () => {},
      dragListener: () => {}
    }),
    props: {
      attachable: {
        type: Object,
        default: () => {
          return {
            attachable_type: null,
            attachable_id: null,
            role: null,
            url: "https://i.picsum.photos/id/239/1010/600.jpg",
            pos_x: 50,
            pos_y: 50,
            scale: 1,
          }
        }
      },
      editable: {
        type: Boolean,
        default: false
      },
      aspectRatio: {
        type: Number,
        default: 1
      },
      endpoint: {
        type: String,
        required: true
      }
    },
    components: {},
    watch: {},
    mounted() {
      this.dragger = this.$refs.dragger;
      this.draggerWidth = this.dragger.clientWidth;
      this.draggerHeight = this.dragger.clientHeight;

      this.posX = parseInt(this.attachable.pos_x);
      this.posY = parseInt(this.attachable.pos_y);

      if (this.editable) {
        const vm = this;
        let img = new Image();
        img.onload = function() {
          vm.naturalWidth = this.width;
          vm.naturalHeight = this.height;
          vm.imageAspectRatio = this.naturalWidth / this.naturalHeight
          vm.opacity = 1;

          vm.initializeListeners();
        }
        img.src = this.attachable.url;
      } else {
        this.opacity = 1;
      }
    },
    beforeDestroy() {
      if (this.editable) {
        this.dragger.removeEventListener('mousedown', this.downListener);
        this.dragger.removeEventListener('mouseup', this.upListener);
        this.dragger.removeEventListener('mousemove', this.dragListener);
      }
    },
    computed: {
      ...mapGetters({

      }),
      cssPosition() {
        return `${this.posX}% ${this.posY}%`;
      }
    },
    methods: {
      ...mapActions({

      }),
      onEditButtonClick() {
        if (!this.editing) {
          this.editing = true;
        } else {
          this.editing = false;
          this.$http.put(this.endpoint, {
            pos_x: this.posX,
            pos_y: this.posY
          }).then(res => {
            this.$emit('adjusted', res.data)
          })
          /*
          this.$emit('adjusted', Object.assign({}, this.attachable, {
            pos_x: this.posX,
            pos_y: this.posY
          }));
           */
        }
      },
      imageLoaded(el) {
        //console.log('imageLoaded', el);
        this.vImgEl = this.$el.querySelector('.v-image__image');
        if (!this.vImgEl) {
          console.error('Cannot proceed without image el');
          return;
        }
      },
      initializeListeners() {
        let vm = this;

        this.downListener = (e) => {
          e.preventDefault();
          vm.deltaX = 0;
          vm.deltaY = 0;
          vm.dragging = true;
          vm.downPos = [e.pageX, e.pageY];
        }

        this.dragger.addEventListener('mousedown', this.downListener);

        this.upListener = () => {
          vm.dragging = false;
          vm.downPos = [0,0];
        }

        window.addEventListener('mouseup', this.upListener);

        this.dragListener = (e) => {
          if (!vm.dragging || !vm.editing)
            return;

          e.preventDefault();

          //vm.downPos = [e.pageX, e.pageY];
          let delta = [
            e.pageX - vm.downPos[0],
            e.pageY - vm.downPos[1]
          ];

          if (vm.imageAspectRatio > this.aspectRatio) {
            // the image is wider than the container, can move along x
            let movement = vm.deltaX - delta[0];
            let newPos = vm.posX + ( (movement / this.draggerWidth) * 100 );
            if (newPos > 100 || newPos < 0)
              return;

            vm.posX = newPos;
            vm.deltaX = delta[0];
          } else if (vm.imageAspectRatio < this.aspectRatio) {
            // the image is taller than the container, can move along y
            let movement = vm.deltaY - delta[1];
            let newPos = vm.posY + ( (movement / this.draggerHeight) * 100 );
            if (newPos > 100 || newPos < 0)
              return;

            vm.posY = newPos;
            vm.deltaY = delta[1];
          }
        };

        this.dragger.addEventListener('mousemove', this.dragListener)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .adjustable-image {
    transition: opacity .75s ease-in;

    .draggable {
      cursor: grab;
    }
  }
</style>
