<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >

    <v-text-field
      autofocus
      outlined
      v-model="email"
      name="email"
      :rules="emailRules"
      label="E-mail"
      :error="errors.has('email')"
      :error-messages="errors.first('email')"
      required
      autocapitalize="off"
      type="email"
    ></v-text-field>

    <v-text-field
      v-model="password"
      outlined
      type="password"
      name="password"
      label="Password"
      hint="Between 8-20 characters with a special character"
    ></v-text-field>

    <v-checkbox
      v-model="remember_me"
      label="Keep me signed in"
      required
    ></v-checkbox>

    <v-btn
      :loading="loading"
      block
      large
      color="primary"
      class="mt-4"
      @click="validate"
    >
      SIGN IN
    </v-btn>

  </v-form>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import {EventBus} from "../utils/events";
  export default {
    data: () => ({
      valid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required'
      ]
    }),
    props: {
      requestedId: {
        type: Number,
        default: null
      }
    },
    components: {},
    watch: {},
    mounted() {

    },
    computed: {
      ...mapGetters({
        loading: 'auth/loading',
        errors: 'auth/errors'
      }),
      email: {
        get() {
          return this.$store.state.auth.email;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {email: val});
        }
      },
      password: {
        get() {
          return this.$store.state.auth.password;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {password: val});
        }
      },
      remember_me: {
        get() {
          return this.$store.state.auth.remember_me;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {remember_me: val});
        }
      },
    },
    methods: {
      ...mapActions({
        signIn: 'auth/signIn'
      }),
      async validate () {
        this.$refs.form.validate();
        this.signIn().then(user => {
          //this.$router.replace({path:'/'});
          console.log('Signed in');
          this.$emit('succeeded', user);
          this.$eventBus.$emit('authenticated', user);
        }).catch(err => {
          // nope
          this.$emit('failed');
        });

      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
