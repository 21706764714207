import {errorableState, errorableMutations, errorableGetters, errorableActions} from "./mixins/errorable";
import Vue from 'vue';

const verificationOptions = [
  {
    title: 'Mobile Number',
    field: 'verify_mobile',
    pattern: /^\+[0-9]{1,3}\s[0-9]{7,13}$/,
    blocks: true,
    blockables:[
      {type:'mobile', title: 'Mobile Numbers', hint: "List numbers one per line starting with country code like +1 2125551212."}
    ],
    description: "Channel is only visible to users with verified mobile number."
  },
  {
    title: 'Instagram',
    field:'verify_instagram',
    blocks: true,
    blockables:[
      {type:'instagram', title: 'Instagram Usernames', hint: "Optional.  Channel becomes totally invisible to users you list here. Ex-lovers, mom, dad, and don't forget grandpa."}
    ],
    description: "Channel is only visible to users with an attached Instagram account."
  },
  {
    title: 'Twitter',
    field:'verify_twitter',
    blocks: true,
    blockables:[
      {type:'twitter', title: 'Twitter Usernames', hint: "When requiring a verified Twitter account you can also block specific twitter handles one per line."}
    ],
    description: "Channel is only visible to members with an attached Twitter account."
  },
  {
    title: 'Facebook',
    field:'verify_facebook',
    blocks: false,
    description: "Channel is only visible to members with an attached Facebook account (recommended for name blocks)."
  },
  {
    title: 'LinkedIn',
    field:'verify_linkedin',
    blocks: false,
    description: "Channel is only visible to members with an attached LinkedIn account (recommended for name blocks)."
  },
];

const definition = {
  id: null,
  title: '',
  slug: '',
  location: '',
  description: '',
  public_rating: 1,
  is_premium: false,
  monthly_tokens: 5,
  verify_authenticated: false,
  location_blocks: [], // city ids
  blocks: {}, // raw blocks content from textarea, see savableBlocks
  avatar: null,
  covers: [],
  verifications: verificationOptions.reduce((a, e) => {
      a[e.field] = false;
      return a;
    }, {}),
}

const state = {
  ...errorableState(),
  ...definition,
  loading: false,
  verificationOptions
}

const getters = {
  ...errorableGetters(),
  id: state => state.id,
  title: state => state.title,
  loading: state => state.loading,
  verifications: state => {
    return state.verifications;
  },
  verificationOptions: state => {
    return state.verificationOptions;
  },
  blocks: state => {
    return state.blocks;
  }
}

const actions = {
  ...errorableActions(),
  async reset( {commit, state}) {
    return commit('UPDATE', definition);
  },
  async load( {commit, state, dispatch}, id) {
    commit('UPDATE', {loading: true});
    commit('UPDATE', definition);

    return Vue.http.get(`managed_channels/${id}`).then(res => {
      if (Array.isArray(res.data.blocks)) {
        res.data.blocks = res.data.blocks.reduce((a,e) => {
          if (!a[e.type])
            a[e.type] = "";

          a[e.type] += `${e.name}\n`;

          return a;
        }, {});
      }

      res.data.verifications = {};
      verificationOptions.map(e => {
        res.data.verifications[e.field] = res.data[e.field];
      })

      commit('UPDATE', res.data);
      commit('UPDATE', {loading: false});
    });
  },
  async save( {commit, state, dispatch, getters } ) {
    commit('CLEAR_ERRORS');

    let payload = {
      title: state.title,
      slug: state.slug,
      location: state.location,
      description: state.description,
      public_rating: state.public_rating,
      location_blocks: state.location_blocks.map(e => e.id),
      is_premium: state.is_premium,
      monthly_tokens: state.monthly_tokens,
      verify_authenticated: state.verify_authenticated,
      verify_mobile: state.verify_mobile,
      verify_instagram: state.verify_instagram,
      verify_twitter: state.verify_twitter,
      verify_facebook: state.verify_facebook,
      verify_linkedin: state.verify_linkedin,
      blocks: Object.keys(state.blocks).reduce((a, k) => {
        state.blocks[k].split(/[\n,]/).map(e => {
          if (!e || e.length == 0)
            return;

          a.push({type: k, name: e.trim()});
        });

        return a;
      }, [])
    };

    Object.keys(state.verifications).map(k => {
      payload[k] = state.verifications[k]
    })

    let prom;
    if (state.id) {
      prom = Vue.http.put(`managed_channels/${state.id}`, payload)
    } else {
      prom = Vue.http.post('managed_channels', payload);
    }

    return prom.then(res => {
      commit('UPDATE', res.data);
    }).catch(res => {
      // set errors...
      dispatch('setApiErrors', res)
    });
  },
  update({ commit, state, dispatch }, data) {
    commit('UPDATE', data);
  },
}

const mutations = {
  ...errorableMutations(),
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
