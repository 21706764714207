import {listableActions, listableMutations, listablesGetters, listableState} from "./mixins/listable";

const state = {
  ...listableState()
}

const getters = {
  ...listablesGetters()
}

const actions = {
  ...listableActions('managed_posts')
}

const mutations = {
  ...listableMutations()
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
