
const state = {
  ready: false, // window.recaptcha is ready
  rendered: false, // a recaptcha problem is loaded
  token: null,
  site_key: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  widgets: {},
  responses: []
}

const getters = {
  token: state => {
    return state.token;
  },
  ready: state => {
    return state.ready;
  },
  rendered: state => {
    return state.rendered;
  },
  widgets: state => {
    return state.widgets;
  },
  responses: state => {
    return {
      for(container) {
        let found = state.responses.find(e => {
          return e.container = container;
        });

        if (!found)
          return null;

        return found.response;
      },
      all() {
        return state.responses;
      }
    };
  },
  allResponses: state => {
    return state.responses;
  }
}

const actions = {
  setReady({ commit}) {
    commit('UPDATE', {ready: true});
  },
  async render( {commit, state, dispatch}, container) {

    if (!state.ready) {
      console.error("Don't recaptcha.render this until you wait for ready");
      return;
    }

    let widgetId;
    widgetId = window.grecaptcha.render(
      container,
      {
        sitekey: state.site_key,
        theme: 'light',
        callback: (response) =>  {
          //console.log('recaptcha!', response);
          commit('SET_CONTAINER_RESPONSE', {container, response});
          commit('SET_WIDGET_RESPONSE', {widgetId, response});
        },
        "expired-callback": () => {
          commit('SET_CONTAINER_RESPONSE', {container, response: null});
        }
      }
    )

    commit('INIT_WIDGET_STATE', widgetId);
  }
}

const mutations = {
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  },
  INIT_WIDGET_STATE: (state, widgetId) => {
    state.widgets[widgetId] = false;
  },
  SET_WIDGET_RESPONSE: (state, {widgetId, response}) => {
    state.widgets[widgetId] = response;
  },
  SET_CONTAINER_RESPONSE: (state, {container, response}) => {
    let idx = state.responses.findIndex(e => {
      return e.container == container;
    });

    if (idx > -1)
      state.responses.splice(idx, 1);

    if (response)
      state.responses.push({container, response});
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
