import Vue from 'vue'
import Vuex from 'vuex'

import app from './app';
import modal from './modal';
import team from './team';
import recaptcha from './recaptcha';
import auth from './auth';
import managed_channels from './managed_channels';
import managed_channel from './managed_channel';
import post from './post';
import posts from './posts';
import ws from './ws';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    modal,
    recaptcha,
    auth,
    team,
    managed_channels,
    managed_channel,
    post,
    posts,
    ws
  }
})
