<template>
  <v-container class="loading fill-height">
    <v-row align="center"
           justify="center"
    >
      <v-col>
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
          class="mx-auto d-block"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Loading',

    data: () => ({

    }),
  }
</script>
