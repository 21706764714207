import Vue from "vue";

export const listableState = () => {
  return {
    items: [],
    page: 1,
    total: 0,
    itemsLoading: false
  };
}

export const listablesGetters = () => {
  return {
    items: state => {
      return state.items;
    },
    itemsLoading: state => {
      return state.itemsLoading;
    }
  };
}

export const listableActions = (endpoint) => {
  return {
    async load({ commit, state, dispatch }, params) {
      if (!params) params = {};
      params = Object.assign({page: 1}, params);

      commit('LISTABLE_UPDATE', {itemsLoading: true});
      return Vue.http.get(endpoint, {
        params
      }).then(res => {
        if (!res) {
          console.error('There is no res');
          return;
        }
        commit('LISTABLE_UPDATE', {items: res.data.data, current_page: res.data.current_page, total: res.data.total})
      }).finally(res => {
        commit('LISTABLE_UPDATE', {itemsLoading: false});
      })
    },
    async delete({ commit, state, dispatch }, id) {
      if (!id)
        return;

      commit('DELETE_LIST_ITEM', id);

      //console.log(`${endpoint}/${id}`);
      return Vue.http.delete(`${endpoint}/${id}`)
    },
    updateListItem( {commit, state}, item ) {
      commit("UPDATE_LIST_ITEM", item);
    }
  };
}

export const listableMutations = () => {
  return {
    UPDATE_LIST_ITEM: (state, updatedItem) => {
      state.items = [...state.items.map(item => item.id !== updatedItem.id ? item : {...item, ...updatedItem})]
    },
    DELETE_LIST_ITEM: (state, id) => {
      let idx = state.items.findIndex(e => id === e.id);

      if (idx < 0)
        return;

      state.items.splice(idx, 1)
    },
    LISTABLE_UPDATE: (state, data) => {
      Object.keys(data).forEach(k => {
        state[k] = data[k];
      });
    }
  };
}
