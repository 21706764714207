<template>
  <v-navigation-drawer
    class="app-drawer-main light-blue accent-4"
    v-model="drawer"
    fixed
    temporary
  >

    <router-link to="/"><v-img
      src="https://xb001.sfo2.digitaloceanspaces.com/assets/xublisher-logo-large-white.png"
      class="mt-9 mb-6"
      contain
      height="60"
    /></router-link>

    <v-list>
      <v-list-item
        v-for="item in menuItems"
        :key="item.title"
        link
        :to="item.to"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-if="userId" v-slot:append>
      <div class="pa-2">
        <v-btn block @click.prevent="logoutHome">Logout</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    data () {
      return {
        items: [
          { title: 'Home', to:"/", icon: 'mdi-home-variant', groups: ['*']},
          { title: 'Sign In', to:"/login", icon: 'mdi-lock-open',  groups: ['guest'] },
          { title: 'Create', to: { path: 'signup', query: { intent: 'create' }}, icon: 'mdi-face-recognition',  groups: ['guest'] },
          { title: 'Creators', to:"/creators", icon: 'mdi-heart-flash', groups: ['user'] },
          { title: 'Contact Us', to:"/contact", icon: 'mdi-message', groups: ['guest'] },
        ]
      }
    },
    computed: {
      ...mapGetters({
        userId: 'auth/id',
        userName: 'auth/name'
      }),
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (value) {
          this.$store.dispatch('app/update', {drawer: value})
        }
      },
      menuItems() {
        return this.items.filter(e => {
          for (const group of this.groups) {
            if (e.groups.indexOf(group) > -1)
              return true;
          }

          return false;
        })
      },
      groups() {
        let groups = ['*'];
        if (this.userId) {
          groups.push('user');
        } else {
          groups.push('guest');
        }
        return groups;
      }
    },
    methods: {
      ...mapActions({
        logout: 'auth/logout'
      }),
      async logoutHome() {
        await this.logout();
        await this.$router.replace('/');
      }
    }
  }
</script>
